import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

initializeApp({
  apiKey: "AIzaSyAQJMuXvkaxi216gUlAFlplcruU-C9il80",
  authDomain: "holipay-api.firebaseapp.com",
  projectId: "holipay-api",
  storageBucket: "holipay-api.appspot.com",
  messagingSenderId: "940949086288",
  appId: "1:940949086288:web:ef9d3390e7ff7948bd7155"
});

const db = getFirestore();
const auth = getAuth();

export { db, auth }